<template>
  <v-app class="bg-white">
    <v-container style="text-align: center !important;" class="">
      <v-row>
        <v-col 
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 4"
          :offset="$vuetify.breakpoint.smAndDown ? 0 : 4"
        >
          <div class="mb-5">
            <img src="/learnbread-primary-logo-removebg.png" alt="Learnbread Logo" class="logo" />
            <h2>Login to Your Account</h2>
          </div>
          <v-alert v-if="error" type="error" color="red">{{ errorMessage }}</v-alert>
          <v-form ref="form" @submit.prevent="submitForm" class="mb-5" >
            <v-text-field v-model="formData.email" label="Email" required :rules="emailRules"></v-text-field>
            <v-text-field
              v-model="formData.password"
              label="Password"
              :type="showPassword ? 'text' : 'password'"
              required
              :rules="passwordRules"
              append-icon="mdi-eye"
              @click:append="togglePasswordVisibility"
            ></v-text-field>
            <v-btn width="100%" type="submit" :disabled="submitting || success" class="primary">Login</v-btn>
          </v-form>
          <router-link to="/forgot-password">Forgot Password?</router-link><br/><br/>
          Don't have an account? <router-link to="/register">Create An Account</router-link>
        </v-col>

      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        email: '',
        password: ''
      },
      success: false,
      submitting: false,
      error: false,
      errorMessage: '',
      showPassword: false
    }
  },
  computed: {
    emailRules() {
      return [
        (v) => !!v || 'Email is required',
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'Invalid email format'
      ]
    },
    passwordRules() {
      return [
        (v) => !!v || 'Password is required',
        (v) => v.length >= 8 || 'Password must be at least 8 characters'
      ]
    }/* ,
    errorMessage() {
      return this.$store.state.auth.errorMessage
    } */
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword
    },
    validateForm() {
      if (this.$refs.form) {
        const isValid = this.$refs.form.validate()

        if (!isValid) {
          //this.error = true
          //this.errorMessage = 'Please fix the form errors.'
        }

        return isValid
      } else {
        console.error('Form reference not found.')

        return false
      }
    },
    async submitForm() {
      this.error = false
      if (this.validateForm()) {
        try {
          this.submitting = true
          // Make the login action call
          const response = await this.$store.dispatch('auth/loginWithLearnbread', {
            email: this.formData.email.toLowerCase(),
            password: this.formData.password
          })

          if (response && response.error) {
            // Handle error response
            this.error = true
            this.errorMessage = response.error
          }

        } catch (error) {
          console.error('Error during login:', error)
          // Handle login error if needed
        } finally {
          this.submitting = false
        }
      }
    }
  }
}
</script>

<style scoped>
/* Add your custom styles here */

.logo {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

body {
  background-color: white !important;
}

.bg-white {
  background-color: white !important;
}

</style>